import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'

const CoachingPage = () => (
  <Layout>
    <Seo
      title="Einzelberatung & Coaching"
      keywords={['Einzelberatung', 'Coaching']}
      description="Mit Beratung und Coaching helfe ich Ihnen in unübersichtlichen, verknoteten und leidvollen Lebenssituationen"
    />
    <article>
      <div className="fl w-100 w-50-ns pr2">
        <StaticImage
          src={'../images/klettern.jpg'}
          alt="Kletterer"
          imgClassName="mb2 mr2"
        />
      </div>
      <div className="fl w-100 w-50-ns">
        <h1>Einzelberatung & Coaching</h1>
        <p>
          Die Inanspruchnahme von Einzelberatung bzw. Coaching kann in
          unübersichtlichen, verknoteten und leidvollen Lebenssituationen sehr
          entlastend sein.
        </p>
        <p>
          Im beruflichen Bereich können Kooperationsprobleme, Zielkonflikte,
          Entscheidungsdilemmata und anderes auftreten. Dabei kann ich Ihnen als
          Berater und Coach zur Seite stehen.
        </p>
        <p>Hier einige Beispielanlässe: </p>
        <ul>
          <li>
            {' '}
            Ein Vater steht dem Wunsch seiner Partnerin nach einem weiteren Kind
            zwiespältig gegenüber
          </li>
          <li>
            {' '}
            Eine beruflich erfolgreiche Frau trägt sich mit dem Gedanken,
            beruflich kürzer zu treten, und möchte mehr Lebensqualität gewinnen
          </li>
          <li>
            {' '}
            Eine Mutter meistert die an sie gestellten familiären und
            beruflichen Anforderungen, wird aber von Ängsten und Selbstzweifeln
            geplagt
          </li>
          <li>
            {' '}
            Ein Schicksalsschlag des Lebens, sei er durch Krankheit, Tod oder
            erlittenen Verlust bedingt, muss bewältigt werden.
          </li>
        </ul>
      </div>
    </article>
  </Layout>
)

export default CoachingPage
